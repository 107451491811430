<template lang="pug" src='./List.pug'></template>
<script>

import { mapActions, mapGetters } from 'vuex'
import { buildPayloadPagination } from '@/utils/utils.js'
import * as types from '@/store/mutation-types'
import {mask} from 'vue-the-mask'

export default{
    directives: {mask},
    data: () => ({
        dataTableLoading: true,
        search: '',
        fieldsToSearch: ['name'],
        expanded: [],
        singleExpand: false,
        itemsPerPage: 10,
        options: {},
        pagination: {
            sortBy: ['name'],
            sortDesc: ['true']
        },
        storesData: [],
        confirmChange: false,
        storeToEdit: null,
        confirmUserChange: false,
        userToEdit: null,
        userIdToDelete: null,
        editUserDialog: false,
        delUserDialog: false,
        editingCnpj: null,
        // user
        userEmail: '',
        userName: '',
        userPassword: '',
        userPhone: '',
        // form
        value: true,
        valid: true,
        rules: {
            required: v => !!v || 'Campo obrigatório'
        },
        emailRules: [
            v => !!v || 'Campo obrigatório',
            v => /.+@.+\..+/.test(v) || 'Digite um e-mail válido',
        ]
    }),
    computed: { 
        ...mapGetters(['stores']),
        storesList: {
            get() {
                return this.storesData
            },
            set(newStores) {
                this.storesData = newStores
            }
        },
        headers() {

        return [
          { text: 'Name', value: 'name' },
          { text: 'CNPJ', value: 'cnpj' },
          { text: 'Ações', value: 'actions'}
        ]
      
    }
    },
    methods: {
        ...mapActions(['getStores', 'updateStore', 'deleteStore', 'deleteUser', 'registerUser', 'updateUser']),
        editUser( user, cnpj ) {
            
            this.editingCnpj = cnpj
            this.userToEdit = user

            this.userName = this.userToEdit.name
            this.userEmail = this.userToEdit.email
            this.userPhone = this.userToEdit.phone

            this.editUserDialog = true
        },
        async saveUser () {

            if(this.$refs.form.validate()) {

                let payload = {
                    name: this.userName,
                    email: this.userEmail,
                    phone: this.userPhone.trim().replace(/[^0-9]/g, ''),
                    store: 'tiptop_'+this.editingCnpj.slice(-2)
                }
                let storeResponse = false

                if( this.userToEdit ) { 
                    payload._id = this.userToEdit._id
                    storeResponse = await this.updateUser(payload) }
                    if( storeResponse ) { 
                        let newUserData = {
                            email: this.userEmail,
                            name: this.userName,
                            phone: this.userPhone.trim().replace(/[^0-9]/g, '')
                        } 
                        this.updateUserById(this.editingCnpj, this.userToEdit._id, newUserData)
                    }
                else { 
                    payload.password = this.userPassword
                    payload.role = "store"
                    storeResponse = await this.registerUser(payload) 
                    if( storeResponse ) { this.addNewUser( this.editingCnpj, payload ) }
                }     
                
                this.closeUserDialog()
            }

        },

        updateUserById(cnpj, userId, newUserData) {
    
            let store = this.storesData.docs.find(store => store.cnpj === cnpj);
            if (store) {
                let user = store.user.find(user => user._id === userId)
                if (user) { Object.assign(user, newUserData) }
            }
        },

        deleteUserById(cnpj, userId) {
            let store = this.storesData.docs.find(store => store.cnpj === cnpj)
            if (store) {
                let userIndex = store.user.findIndex(user => user._id === userId)
                if (userIndex !== -1) { store.user.splice(userIndex, 1) }
            }
        },

        addNewUser(cnpj, userData) {
            this.storesData.docs.forEach(doc => {
                if (doc.cnpj === cnpj) {
                    doc.user.push(userData)
                }
            })
        },

        confirmDeleteUser(user, cnpj) {
            this.userToEdit = user
            this.editingCnpj = cnpj
            this.delUserDialog = true
        },
        async delUser() {
            let storeResponse = await this.deleteUser({ _id: this.userToEdit._id })
            if(storeResponse) { this.deleteUserById( this.editingCnpj, this.userToEdit._id ) }
            this.closeUserDialog()
        },
        openAddUser( cnpj ) {
            this.editingCnpj = cnpj
            this.userToEdit = null
            this.editUserDialog = true
            setTimeout(() => { this.passwordSuggest(); }, 2000)
        },
        openEditDialog(store) {
            this.storeToEdit = store
            this.confirmChange = true
        },
        closeEditDialog() {
            this.storeToEdit = null
            this.confirmChange = false
        },
        closeUserDialog() {
            this.userToEdit = null
            this.editingCnpj = null

            if(this.$refs.form) { this.$refs.form.reset() }
            
            this.userName = ''
            this.userEmail = ''
            this.userPhone = ''

            this.editUserDialog = false
            this.delUserDialog = false
        },

        edit ( store ) {
            this.$store.commit(types.STORE_DATA, store)
            this.$router.push(`/stores/Edit/${store._id}`)
        },
        async del () { 
            
            await this.deleteStore({
                _id: this.storeToEdit._id,
            })

            this.storesList.docs = this.storesList.docs.map(store => store._id === this.storeToEdit._id ? { ...store, deleted: true } : store )
            this.confirmChange = false
        },
        toggleExpand(item) {
            if (this.expanded.includes(item)) {
                this.expanded = this.expanded.filter(i => i !== item)
            } else { this.expanded.push(item) }
        },
        formatCNPJ(cnpj) {
            const regex = /^\d{14}$/
            if ( regex.test(cnpj) && !/^(\d)\1+$/.test(cnpj) ) {
                return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5")
            }else return cnpj
        },
        formatPhoneNumber(phoneNumber) {
            const cleaned = ('' + phoneNumber).replace(/\D/g, '');
            const match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/)
            return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`
        },
        passwordSuggest(size = 8) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            let password = ''
    
            for (let i = 0; i < size; i++) {
                const index = Math.floor(Math.random() * characters.length)
                password += characters.charAt(index)
            }
    
            this.userPassword = password
        },

        async doSearch() {

            this.pagination.page = this.options.page
            this.pagination.itemsPerPage = this.options.itemsPerPage
            try {
                this.dataTableLoading = true
                await this.getStores( buildPayloadPagination(this.pagination, this.buildSearch()) )
                this.storesData = this.stores
                this.dataTableLoading = false
            } catch (error) {
                this.dataTableLoading = false
            }

        },
        buildSearch() {
            return this.search
                ? { query: this.search, fields: this.fieldsToSearch.join(',') }
                : {}
        }
    },
    mounted() {
        this.getStores()
    },
    
    watch: {
        options: {
          handler () {
            this.doSearch()
        },
        deep: true,
        }
  } 
}

</script>
<style>
tr.v-data-table__expanded__content{
    box-shadow: none !important;
    background-color: #F9F9F9;
}

</style>